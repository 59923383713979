<template>
  <div
    class="flex min-h-screen w-full flex-col justify-between overflow-x-hidden"
    :class="{ 'pt-nav-sm xl:pt-nav-lg': $route.path !== '/' }"
  >
    <MainHeader class="fixed top-0 z-top w-full xl:top-6" />

    <main class="flex-1">
      <slot />
    </main>

    <AppFooter />

    <BaseBreaky />
  </div>
</template>
